import React, { useState } from "react"
import { navigate } from "gatsby"
import Recaptcha from "react-google-recaptcha"
import { FormWrapper } from "./Contact.styles"
import { Container, Col, Row, Card } from "react-bootstrap"
import "react-phone-number-input/style.css"
import PhoneInput from "react-phone-number-input"

const RECAPTCHA_KEY = "6Lee28wZAAAAAChAhnCDPD9WL-W6X729V09AOrI7"

const TestForm = () => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(undefined);

  const [isValid, setValid] = useState(true)
  const [isTouched, setTouched] = useState(false)
  const [name, setName] = useState()
  const [email, setEmail] = useState()
  const [company, setCompany] = useState()
  const [message, setMessage] = useState()
  const [contact, setContact] = useState()

  const recaptchaRef = React.createRef()
  const [buttonDisabled, setButtonDisabled] = React.useState(true)

 
  function nameHandler(event) {
    setName(event.target.value)
  }
  function emailHandler(event) {
    setEmail(event.target.value)
    if (emailIsValid(event.target.value)) setValid(true)
    else if (event.target.value === null) setValid(false)
    else setValid(false)
  }
  function companyHandler(event) {
    setCompany(event.target.value)
  }
  function messageHandler(event) {
    setMessage(event.target.value)
  }

  function emailIsValid(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }

  function isNum(val) {
    return /^\d+$/.test(val)
  }

  const handleSubmit = e => {
    e.preventDefault()
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);

    const form = e.target
    const captchaResponse = recaptchaRef.current.getValue()
    const jsonobj = {
      fullName: name,
      email: email,
      companyName: company,
      mobileNo: contact,
      message: message,
      designation: "null",
      fromEmail: "info@edxlabs.com",
      captchaResponse: captchaResponse,
    }

    fetch("https://ciru-form-automation.ctm360.com/sendemail", {
      method: "POST",
      headers: { "Content-Type": "application/json; charset=utf-8",  "dataType": "json"},
      body: JSON.stringify(jsonobj),
    })
    .then(() => {
      setStatus({ message: 'Success' });
    })
      .catch(error => alert(error))
  }

  return (
    <FormWrapper>
      <h3>
        Get in <strong>Touch</strong>
      </h3>
      <form name="contact-us" method="POST" onSubmit={handleSubmit}>
        <noscript>
          <p>This form won’t work with Javascript disabled</p>
        </noscript>
        <div className="form-group">
          <label htmlFor="name">Your Full Name</label>
          <input
            type="text"
            className="form-control"
            id="name"
            name="fullName"
            value={name}
            onChange={nameHandler}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="email">Your Business Email</label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            aria-describedby="emailHelp"
            value={email}
            onChange={emailHandler}
            required
            onBlur={() => setTouched(true)}
          />

          {isTouched && !isValid && (
            <p style={{ color: "red", fontSize: "12px" }}>
              Please enter valid value.
            </p>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="company">Your Company</label>
          <input
            type="text"
            className="form-control"
            id="company"
            name="companyName"
            value={company}
            onChange={companyHandler}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="contact">Contact Number</label>
          <PhoneInput
            defaultCountry="BH"
            id="contact"
            name="mobileNo"
            value={contact}
            onChange={setContact}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Message</label>
          <textarea
            className="form-control"
            rows="5"
            id="message"
            name="message"
            value={message}
            onChange={messageHandler}
          />
        </div>
        {/* Recaptcha */}
        <Recaptcha
          ref={recaptchaRef}
          sitekey={RECAPTCHA_KEY}
          size="normal"
          id="recaptcha-google"
          onChange={() => setButtonDisabled(false)}
        />
        <button type="submit" disabled={buttonDisabled}>
          Submit {loading && <p>Loading</p>}
        </button>

        {status?.message === 'Success' && <p className="success-message">Thanks for contacting us, will get back to you</p>}
      </form>
    </FormWrapper>
  )
}

export default TestForm

import React from 'react'
import GoogleMapReact from 'google-map-react';
import Marker from "google-map-react";
import { MapWrapper } from "./Contact.styles";

const defaultProps = {
  center: {
    lat: 26.23,
    lng: 50.54,
  },
  zoom: 13,
}

const AnyReactComponent = ({ text }) => <div className="map-title"><a href="https://goo.gl/maps/Msz9j8dmwU4KduSCA" target="_blank">{text}</a></div>

const ContactMap = () => (
  <MapWrapper style={{ height: '350px', width: '100%' }}>
    <GoogleMapReact
      bootstrapURLKeys={{ key: 'AIzaSyANn5wNBuLJt-hd8EEsN9FpyYDmAo4S2vQ' }}
      defaultCenter={defaultProps.center}
      defaultZoom={defaultProps.zoom}
    >
     
      <AnyReactComponent
        lat={26.234037033948994}
        lng={50.543342671164346}
        text={'EDXLABS'}
      />
    </GoogleMapReact>
  </MapWrapper>
)

export default ContactMap
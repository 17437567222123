import * as React from "react"
import { Link } from "gatsby"
import 'bootstrap/dist/css/bootstrap.min.css';


import Layout from "../components/Layout/Layout"
import Seo from "../components/seo";
import ContactForm from "../components/Contact/ContactForm";
import {Container, Col, Row, Card} from "react-bootstrap";
import PageBanner from "../components/UI/PageBanner";

const ContactPage = () => (
  <Layout>
    <Seo title="Contact" />
      <div>
        <PageBanner heading="Contact Us"></PageBanner>
        <ContactForm></ContactForm>       
      </div>

  </Layout>
)

export default ContactPage

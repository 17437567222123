import styled from "styled-components"

export const MapWrapper = styled.div`
    margin-top: 40px;
  .map-title{
    a{
    
        color: #fff; 
        background: #bc0000;
        padding: 15px 10px;
        display: inline-flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        transform: translate(-50%, -50%);
       text-decoration: none;
    }
  }

`



export const FormWrapper = styled.div`
    padding: 20px 20px;

    h3{
        color: #333;
        font-size: 35px;
        font-weight: 500;
        margin-bottom: 30px;
        strong{
            font-weight: 700;
        }
    }
    .sub-title{
        color: #2A2A2A;
        font-family: "Montserrat", Sans-serif;
        font-size: 60px;
        font-weight: bold;
        font-style: italic;
        margin-bottom: 40px;
    }
    .form-group{ 
        margin-bottom:20px;
    label{
        font-family: 'Montserrat',sans-serif;
        font-size: 15px;
        color: #000;
        font-weight: 500;
        width: 100%;
        display: block
       }
    input{
        color: #666;
        padding: 8px 10px;
        height: auto;
        width: 100%;
        border-width: 1px;
        border-style: solid;
        border-color: #eaeaea;
        border-radius: 2px;
        background: #fafafa;
        box-shadow: none;
        box-sizing: border-box;
        transition: all .2s linear;
       }  
    textarea{
        display: block;
        width:100%;
        border-color: #eaeaea;
        border-radius: 2px;
        background: #fafafa;
    }  
    }
    button{
        color: #ffffff;
        border-color: #bc0000;
        background-color: #bc0000;
        margin: 9px 0;
    padding: 3px 24px;
    border-radius: 5px;
        }

    .contact-desc-text{
        max-width: 450px;
        p{
            color: #000000;
            font-family: "Montserrat", Sans-serif;
            font-size: 20px;
            font-weight: 600;

            span{
                color: #000000;
                font-family: "Montserrat", Sans-serif;
                font-size: 16px;
                font-weight: 400;
                display: block;
            }
        }
        
        a{
           text-decoration: none;
           color: #bc0000;
           font-size: 16px; 
           font-family: "Montserrat", Sans-serif;
            display: block;

           span{
            color: #777;
            font-weight: 600;
           }
        }
    }

    .vertical_center{
        align-self: center;
        height: 100%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
      }

`